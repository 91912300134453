import React from 'react';
import cx from 'classnames';
import { Link, graphql } from 'gatsby';
import DefaultLayout from '../layouts';
import { getComponent } from '../utils';
import ProductCard from '../components/ProductCard/ProductCard';
import Hero from '../components/Hero/Hero';
import styles from '../components/ProductCard/styles.module.scss';

const SubscribePage = ({ data }) => {
  const [size, setSize] = React.useState('mao'); // mao = standard, pmao = pawty

  return (
    <DefaultLayout
      pageTitle={data.contentfulPage.title}
      metaData={data.contentfulPage.metaData || undefined}
      contentfulPage={data.contentfulPage}
    >
      <div className="base">
        <div className="container text-center">
          {data.contentfulPage.content.map(context => {
            const Component = getComponent(
              context.internal.type.replace('Contentful', '')
            );

            return <Component key={context.id} {...context} />;
          })}
        </div>
      </div>
      <div className="base">
        <div className="toggle">
          <button
            className={cx('btn btn--toggle', { active: size === 'mao' })}
            onClick={() => setSize('mao')}
          >
            Standard
          </button>
          <button
            className={cx('btn btn--toggle', { active: size === 'pmao' })}
            onClick={() => setSize('pmao')}
          >
            Pawty
          </button>
          <button
            className={cx('btn btn--toggle', { active: size === 'jmao' })}
            onClick={() => setSize('jmao')}
          >
            Jumbo
          </button>
        </div>
        <div className="container text-center">
          <ul className={styles.products}>
            {data.shopifyCollection.products
              .filter(product =>
                product.variants[0].sku.match(new RegExp(`\\b${size}`, 'gi'))
              )
              .map(({ shopifyId, ...rest }) => (
                <ProductCard key={shopifyId} {...rest} />
              ))}
          </ul>
        </div>
      </div>
    </DefaultLayout>
  );
};
export default SubscribePage;

export const query = graphql`
  {
    contentfulPage(contentful_id: { eq: "64tvFo4Wv9QgeqoeGrl1rc" }) {
      id
      title
      metaData {
        ... on ContentfulPageMeta {
          title
          metaDescription
        }
      }
      hero {
        contentful_id
        heading
        image {
          id
          file {
            url
          }
        }
        ctaButton {
          buttonLink
          label
        }
      }
      content {
        ... on ContentfulContentBlock {
          id
          title
          displayTitle
          textAlignment
          body {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                title
                file {
                  url
                }
              }
            }
          }
          callToAction {
            id
            buttonLink
            label
          }
          internal {
            type
          }
        }
      }
    }
    shopifyCollection(title: { eq: "Boxes" }) {
      shopifyId
      products {
        handle
        title
        shopifyId
        descriptionHtml
        vendor
        productType
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        variants {
          shopifyId
          storefrontId
          title
          sku
          price
          presentmentPrices {
            price {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`;
